import './App.css';
import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react';
import { Layout, Row, Col, Progress, Form, Input, Divider, notification } from 'antd';
import { MailOutlined, FacebookOutlined, MessageOutlined, MinusOutlined, LoadingOutlined, LinkedinOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
import emailjs from '@emailjs/browser';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);




function App() {

    

    let skills=[
        {name: 'HTML5', percentage: '90'},
        {name: 'CSS3', percentage: '80'},
        {name: 'JAVASCRIPT', percentage: '70'},
        {name: 'REACT', percentage: '70'},
        {name: 'PHP', percentage: '50'},
        {name: 'SYMFONY', percentage: '50'},
        {name: 'EXPRESS.js', percentage: '65'},
        {name: 'GSAP', percentage: '50'},
        {name: 'MONGO DB', percentage: '70'},
        {name: 'MYSQL', percentage: '50'},
        {name: 'GIT / GITHUB', percentage: '80'},
        {name: 'FIGMA / ILLUSTRATOR', percentage: '70'},
    ];

    let projects=[
        {title: 'ATS Construction Rénovation', src: './images/ats.png', decription: " Site vitrine d'une entreprise de menuiserie développé en React JS."},
        {title: 'Masques.org', src: './images/masques.png', decription: "Web App développée en React JS permettant de mettre en relation fabricants français et toute personne intéressée par des masques personnalisés, afin de favoriser les circuits courts et l'économie locale."},
        {title: 'Morning News', src: './images/morning-news.png', decription: "Application web développée en React JS qui permet d’afficher les news actualisées et de les mettre dans une wishlist pour les sauvegarder en base de données et les lire plus tard."},
        {title: 'Moviz', src: './images/my-moviz.png', decription: "Web App développée en React JS connectée directement à une API permettant de récupérer les informations des films. Elle propose une liste de films, avec les dernières sorties cinéma à jour, et vous permet de créer votre propre wishlist."},
        {title: 'Tic et Tac', src: './images/ticettac.png', decription: "Site de réservation de train développée en React avec base de donnée."},
        {title: 'Blackboard', src: './images/blackboard.png', decription: "Dashboard de commandes de trottinettes électriques.Ce dashboard permet de suivre les commandes de trottinettes effectuées par les clients ou encore les messages que les clients vous envoient. Il permet également de visualiser les tâches en attente."}
    ];

    const [onLoading, setOnLoading] = useState(false);
      
      

    const [form] = Form.useForm();
    
    const onFinish = (values, r) => {
        console.log('Success:', values);
        setOnLoading(true);

        const serviceId = "service_a8arfyi";
        const templateId = 'template_jevdllo';
        var templateParams = {
            name: values.name, 
            email: values.email,
            subject: values.subject,
            message: values.message
        };

        emailjs.send(serviceId, templateId, templateParams, 'Sz-xX7e07NZiLVHNU')
        .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);

        setOnLoading(false);

        notification.open({
            message: "Message envoyé!",
            description: "Merci pour votre message, il sera traité au plus vite!",
            duration: 5,
            className: 'notification'
        });

        form.resetFields();

    }, function(error) {
            console.log('FAILED...', error);

            setOnLoading(false);

            notification.open({
                message: "Oups!",
                description: "Un problème est survenu, votre message n'a pas été envoyé. Veuillez réessayer dans un moment.",
                duration: 5,
                className: 'notification'
            });
        });
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    //SLIDE SECTION
    const slideInTop= (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                opacity: 0,
                y: -300,
            },
            {
                opacity: 1,
                y: 0,
                dalay: delay || 0.4, 
                duration: duration || 1, 
                scrollTrigger: {
                    trigger: elem,
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play reverse play reverse"
                }
            }
        )
    }

    //SHRINK A PROPOS  
    const onAboutMe= (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                x: gsap.utils.wrap(['-3%', '3%']),
            },
            {
                x: 0,
                dalay: delay || 0.4, 
                duration: 2,
                stagger: 0.3,
                ease: 'ease in out', 
                scrollTrigger: {
                    trigger: ".itemAboutMe",
                    start: "top 80%",
                    end: "bottom 30%",
                    toggleActions: "play reverse play reverse", 
                },
            }
        )
    } 

    //ANIMATION TIMELINE
    const onTimeline= (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                y: gsap.utils.wrap([15, -15]),
            },
            {
                y: 0,
                dalay: delay || 0.4, 
                duration: 1.5,
                stagger: 0.3,
                ease: 'ease in out', 
                scrollTrigger: {
                    trigger: ".itemTimeline",
                    start: "top 80%",
                    end: "center 20%",
                    toggleActions: "play reverse play reverse",
                
                },
            }
        )
    } 

    //ANIMATION CASE EXP/CLIENTS/PROJETS
    const slideCase= (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                y: 10
            },
            {
                y: 0,
                dalay: delay || 0.4, 
                duration: duration || 2, 
                scrollTrigger: {
                    trigger: ".squareSkills",
                    start: "top bottom",
                    end: "top 10%",
                    toggleActions: "play reverse play reverse",
                }
            }
        )
    } 

    const slideCase2= (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                y: -10
            },
            {
                y: 0,
                dalay: delay || 0.4, 
                duration: duration || 2, 
                scrollTrigger: {
                    trigger: ".squareSkills2",
                    start: "top bottom",
                    end: "top 10%",
                    toggleActions: "play reverse play reverse",
                }
            }
        )
    } 

    //ANIMATION ROTATE LOGO
    const logoRotate= (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                opacity: 0
            },
            {
                opacity: 1,
                rotateY: 360,
                dalay: delay || 0.4, 
                duration: duration || 3, 
                scrollTrigger: {
                    trigger: "#logo",
                    toggleActions: "play reverse play reverse",
                }
            }
        )
    } 

    useEffect(() => {
        slideInTop("#section1");
    }, [])

    useEffect(() => {
        logoRotate("#logo");
    }, [])

    useEffect(() => {
        onAboutMe(".itemAboutMe")
    },[])

    useEffect(() => {
        slideCase(".squareSkills");
    }, [])

    useEffect(() => {
        slideCase2(".squareSkills2");
    }, [])

    useEffect(() => {
        onTimeline(".itemTimeline");
    }, [])


    return (
        <div className="App">

        <Helmet>
            <meta charSet="utf-8" />
            <title>Loëvane - Développeuse Web</title>
            <link rel="canonical" href="http://loevane-vircondelet.fr/" />
            <meta
                name="Loëvane - Développeuse Web"
                description="Portfolio de Loëvane Vircondelet Gelot, développeuse web."
                content="Portfolio de Loëvane Vircondelet Gelot, développeuse web."
            />
            <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo4.png" />
            <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        </Helmet>


            <img src='./logo4.png' alt='logo' width={20} className='logo'/>

            <Layout className='layout home' id='section1'>

                <Row justify='end' align='top'>
                
                    <div className='socialResHome'>
                        <MinusOutlined style={{transform: 'rotate(90deg)', fontSize: '22px', margin: '10px',  cursor: 'initial'}}/>
                        <a href='mailto:loevane.vircondelet@gmail.com' >
                            <MailOutlined className='icon1'/>
                        </a>
                        <a href='https://www.m.me/loevane.vircondelet1/' target="_blank" rel="noreferrer">
                            <MessageOutlined className='icon1'/>
                        </a>
                        <a href= 'https://www.linkedin.com/in/lo%C3%ABvane-vircondelet/' target="_blank" rel="noreferrer">
                            <LinkedinOutlined className='icon1'/>
                        </a>
                        <MinusOutlined style={{transform: 'rotate(90deg)', fontSize: '22px', margin: '10px', cursor: 'initial'}}/>  
                    </div>

                </Row>

                <Row>

                <div className='textHome'>
                    <h1>Loëvane</h1>
                    <h2>Web Développeuse</h2>
                </div>

                </Row>

            </Layout>

            <Layout className='layout'>

                <Row justify='center' align='middle'>

                    <Col span={{sm: 22, md: 10}} className='itemAboutMe'>
                        <div className='squareAboutMe'>
                        <img src='../images/pp.jpg' alt='moi' className='photoOfMe'/>
                        </div>
                    </Col>

                    <Col sm={{span: 15}} md={{span: 11, offset: 1}} className='itemAboutMe' >
                        <h3>A propos de moi</h3>
                        <p>
                            Salut tout le monde !<br/> Je suis une développeuse web en herbe, actuellement en formation pour obtenir mon titre professionnel de niveau 5. Mon objectif ultime est de devenir une véritable ninja de la programmation web, et je suis à la recherche d'une alternance pour parfaire mes compétences et apprendre aux côtés de professionnels aguerris.
                            <br/>
                            Je suis à la recherche d'une alternance pour perfectionner mes compétences en développement web et me confronter à des situations réelles. Si vous cherchez une personne motivée, créative et passionnée par le café pour rejoindre votre équipe de développeurs, alors je suis la candidate idéale pour vous !
                            <br/>
                            Je suis toujours prête à apprendre de nouvelles technologies web, à relever des défis et à travailler dur pour atteindre mes objectifs. Si vous êtes prêts à m'accueillir à bord et à partager une tasse de café avec moi, alors let's code together ! ☕️ 🚀
                        </p>

                        <a target='blank' href='../pdf/CV_dev.pdf'>
                            <button className='button1' htmlType="submit">
                                MON CV
                            </button>
                        </a>
                    </Col>

                </Row>

                <Row justify='center'>

                    <Col span={{sm: 24, md: 4}}>
                        <div className='itemTimeline'>
                            <Icon icon="ph:coffee" width={40}/>
                            <p>Café</p>
                        </div>
                    </Col>
                    <Col span={{sm: 24, md: 4}}>
                        <div className='itemTimeline'>
                            <Icon icon="fluent:clipboard-search-20-regular" width={40}/>
                            <p>Analyse</p>
                        </div>
                    </Col>
                    <Col span={{sm: 24, md: 4}}>
                        <div className='itemTimeline'>
                            <Icon icon="mdi:fountain-pen" width={40}/>
                            <p>Design</p>
                        </div>
                    </Col>
                    <Col span={{sm: 24, md: 4}}>
                        <div className='itemTimeline'>
                            <Icon icon="material-symbols:code-blocks-outline-sharp" width={40} />
                            <p>Code</p>
                        </div>
                    </Col>
                    <Col span={{sm: 24, md: 4}}>
                        <div className='itemTimeline'>
                            <Icon icon="fluent-mdl2:test-auto-solid" width={40}/>
                            <p>Test</p>
                        </div>
                    </Col>
                    <Col span={{sm: 24, md: 4}}>
                        <div className='itemTimeline'>
                            <Icon icon="fa:send-o" width={40} />
                            <p>Envoi</p>
                        </div>  
                    </Col>

                </Row>

            </Layout>

            <Layout className='layout'>

                <h3>Compétences</h3>

                <Row justify='space-around' align='middle'  className='skills'>

                    {skills.map((skill, i)=>(
                        <Col span={10} key={i}>
                            <p>{skill.name}</p>
                            <Progress percent={skill.percentage} showInfo={false} status='active' strokeColor={'#C22C48'} className='progressBar'/>
                        </Col>
                    ))}

                </Row>
            
            </Layout>

            {/*<Layout className='layout'>

                <Row justify='center'>
                    <h4>Responsive Design</h4>
                </Row>
                
                <Row justify='center'>
                    <Col sm={{span:24}} md={{span: 12}}>
                        <img src='./images/responsiveDesign.png' alt='responsive-design' width='100%'/>
                    </Col>
                </Row>

                <Row justify='center'>
                    <Col sm={{span: 24}} md={{span: 12}}>
                        <p className='textResponsive'>La technologie responsive design permet aux utilisateurs de parcourir votre site de manière fluide sur n’importe quel support : ordinateur, tablette, smartphone.</p>
                    </Col>
                </Row>

            </Layout>*/}

            <Layout className='layout'>

                <Row justify='center' align='middle' style={{margin: '50px 0'}} >


                    <Col sm={{span: 24}} md={{span:5}} className='squareSkills'>
                    <Icon icon="teenyicons:folder-tick-solid" color='#C22C48'  width='60px'/>
                        <p className='titleSquareSkills1'>+ 30</p>
                        <p>projets complétés</p>
                    </Col>
                    <Col sm={{span: 24}} md={{span:5}} className='squareSkills2'>
                        <Icon icon="material-symbols:folder-open-rounded" color='#20242B' width="60px" />
                        <p className='titleSquareSkills2'>4</p>
                        <p>projets en cours</p>
                    </Col>
                    <Col sm={{span: 24}} md={{span:5}} className='squareSkills'>
                        <Icon icon="simple-icons:buymeacoffee" color='#C22C49' width='60px'/>
                        <p className='titleSquareSkills1'>+ 200</p>
                        <p>cafés consommés</p>
                    </Col>

                </Row>

                <Row justify='space-around' align='middle' className='annoucement'>

                    <Col span={{sm:24, md:8}}>
                        <img src='../icons/announcement.png' alt='test' width={80}/>
                    </Col>
                    <Col span={{sm:24, md:8}}>
                        <p style={{fontSize: '30px', fontFamily: "Playfair Display, sans-serif", fontWeight: 'bold', margin: '5px'}}>BESOIN D'UNE DÉVELOPEUSE WEB ?</p>
                        <p>Je suis disponible pour rejoindre une équipe passionnée et désireuse de relever de nouveaux défis!</p>
                    </Col>
                        <Col span={{sm:24, md:8}}>
                        <a href='mailto:loevane.vircondelet@gmail.com' >
                            <button className='button2'>
                                CONTACTEZ-MOI
                            </button>
                        </a>
                    </Col>

                </Row>

            </Layout>

            <Layout className='layout'>

                <h3>Portfolio</h3>

                <Row justify='center' align='middle'>

                    {projects.map((project, i)=>(

                        <Col span={{sm: 24, md: 7}} key={i}>
                            <div className='boxProject'>
                                <img src={project.src} alt={project.title} className='imgProjet' />
                                <div className='overlay'>
                                    <div className='textProject'>
                                        <p style={{fontSize: '20px', fontWeight: 'bold', color: '#C22C48'}}>{project.title}</p>
                                        <p>{project.decription}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}

                </Row>

            </Layout>

            <Layout className='layout'>

                <h3>Contactez-moi</h3>

                <Row justify='center'>
                    <Col span={{sm: 24, md: 24}}>

                        <Form
                            name="contact-me"
                            form={form}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="on"
                            className='formContact'
                            size='large'
                        >
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: 'Veuillez saisir votre nom!' }]}
                        >
                            <Input placeholder='Ton nom complet' />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Veuillez saisir votre email!', type: "email" }]}
                        >
                            <Input placeholder='Ton email'/>
                        </Form.Item>

                        <Form.Item
                            name="subject"
                            rules={[{ required: true, message: 'Veuillez saisir le sujet!' }]}
                        >
                            <Input placeholder='Sujet'/>
                        </Form.Item>

                        <Form.Item 
                            name="message"
                            rules={[{ required: true, message: 'Veuillez saisir votre message!' }]}
                        >
                            <Input.TextArea placeholder='Ton message' rows={6}/>
                        </Form.Item>

                        {/*<p style={{fontStyle: 'italic', margin: '50px 0 10px 0'}}>Si tu souhaites faire une demande de devis, explique ton projet en détail au maximum.</p>*/}

                        <Form.Item>
                            <button className='button1' htmlType="submit">
                                {onLoading? <LoadingOutlined spin/>
                                : "Envoyer"}
                            </button>
                        </Form.Item>
                        
                        </Form>

                    </Col>
                </Row>

            </Layout>

            <div className='footer'>

                <Divider style={{backgroundColor: '#343942'}}/>

                <Row justify='center'>
                    <img src='./logo4.png' alt='logo' width={50} id="logo" />
                </Row>

                <Row justify='center'>
                    <p>@2023 Loëvane Vircondelet Gelot, tous droits réservés.</p>
                </Row>

                <Row justify='center'>
                    <Col sm={{span: 8}} md={{span: 1}}>
                        <a href='mailto:loevane.vircondelet@gmail.com'>
                            <MailOutlined className='icon1'/> 
                        </a>
                    </Col>
                    <Col sm={{span: 8}} md={{span: 1}}>
                        <a href='https://www.m.me/loevane.vircondelet1/' target="_blank" rel="noreferrer">
                            <MessageOutlined className='icon1'/> 
                        </a>
                    </Col>
                    <Col sm={{span: 8}} md={{span: 1}}>
                        <a href= 'https://www.linkedin.com/in/lo%C3%ABvane-vircondelet/' target="_blank" rel="noreferrer">
                            <LinkedinOutlined className='icon1'/>
                        </a>
                    </Col>


                    
                </Row>
            
            </div>    
        
        </div>
    );
}

export default App;
